<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      :title="`Табель (${name})`"
    >
      <v-toolbar flat>
        <v-row>
          <v-col cols="12" lg="10" class="d-flex justify-start">
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Сегодня</v-btn>
            <v-btn outlined fab text small color="grey darken-2" icon @click="prev"><v-icon small>mdi-chevron-left</v-icon></v-btn>
            <v-btn outlined fab text small color="grey darken-2" icon @click="next"><v-icon small>mdi-chevron-right</v-icon></v-btn>
            <v-toolbar-title class="ml-4 text-uppercase">{{ calendarTitle }}</v-toolbar-title>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-row>
        <v-col cols="12">
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              color="primary"
              v-model="focus"
              :events="events"
              :event-overlap-threshold="30"
              type="month"
              locale="ru-ru"
              :weekdays="[1, 2, 3, 4, 5, 6, 0]"
              @change="calendarChange"
              show-week
              :short-weekdays="false"
            >
              <template v-slot:day-label="item">
                <v-btn
                  fab
                  small
                  :color="item.present?'primary':''"
                  class="mb-1"
                  @click.native="showDay($event, item)"
                >{{ item.day }}</v-btn>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column">
          <div class="d-flex justify-start align-center mb-3">
            <v-chip x-small color="red" style="width: 100px"></v-chip><span>&nbsp;- Выходной</span>
          </div>
          <div class="d-flex justify-start align-center">
            <v-chip x-small color="orange" style="width: 100px"></v-chip><span>&nbsp;- Короткий день</span>
          </div>
        </v-col>
      </v-row>
    </base-card>

    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
    >
      <v-card>
        <v-toolbar color="red" dark dense>
          <v-toolbar-title>Добавить событие</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-tabs vertical v-model="tab">
            <v-tab>Рабочий</v-tab>
            <v-tab>Отгул</v-tab>
            <v-tab>Больничный</v-tab>
            <v-tab>Отпуск</v-tab>
            <v-tab-item>
              <span>Рабочий день. Можно указать время работы</span>
              <v-masked-input
                v-model="time"
                masked
                label="Рабочее время"
                :mask="'##:##'"
              ></v-masked-input>
            </v-tab-item>
            <v-tab-item>Выбранный день отметится как отгул</v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col>
                  <span>Выберите интервал дат для больничного</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6">
                  <date-picker v-model="startDate" label="Дата начала" dense clearable @click:clear="startDate = null"/>
                </v-col>
                <v-col cols="12" lg="6">
                  <date-picker v-model="endDate" label="Дата окончания" dense clearable @click:clear="endDate = null"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-combo-multisearch
                  v-model="repl_id"
                  :items="users"
                  item-value="id"
                  item-text="full_name"
                  item-desc="position"
                  label="Выбрать заместителя"
                  avatar
                  clearable
                />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col>
                  <span>Выберите интервал дат для отпуска</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6">
                  <date-picker v-model="startDate" label="Дата начала" dense clearable @click:clear="startDate = null"/>
                </v-col>
                <v-col cols="12" lg="6">
                  <date-picker v-model="endDate" label="Дата окончания" dense clearable @click:clear="endDate = null"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-combo-multisearch
                  v-model="repl_id"
                  :items="users"
                  item-value="id"
                  item-text="full_name"
                  item-desc="position"
                  label="Выбрать заместителя"
                  avatar
                  clearable
                />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn text @click="deleteEvent">удалить</v-btn>
          <div>
            <v-btn text @click="selectedOpen = false">отмена</v-btn>
            <v-btn text @click="saveEvent" color="success">сохранить</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-container>
</template>

<script>
import VComboMultisearch from '../../components/VComboMultisearch'
import VMaskedInput from '../../components/VMaskedInput'
export default {
  name: 'TimeSheet',
  components: {
    VMaskedInput,
    VComboMultisearch
  },
  data: () => ({
    type: ['work', 'day_off', 'sick_leave', 'vacation'],
    events: [],
    focus: '',
    year: new Date().getFullYear(),
    start: null,
    user_id: null,
    name: '',
    selectedOpen: false,
    selectedEvent: {},
    selectedElement: null,
    tab: null,
    time: '08:00',
    startDate: null,
    endDate: null,
    repl_id: null,
    users: []
  }),
  created () {
    this.user_id = Number(this.$route.params.id)
    this.$eventHub.$on('back', this.goBack)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  computed: {
    calendarTitle () {
      if (this.focus === '') {
        return this.$moment().format('MMMM YYYY')
      }
      return this.$moment(this.focus, 'YYYY-MM-DD').format('MMMM YYYY')
    }
  },
  methods: {
    getData (year, month) {
      this.$store.dispatch('hr/GET_CALENDAR', { year: year, month: month, user_id: this.user_id }).then(response => {
        this.events = response.events
        this.name = response.user
        this.users = response.users
      })
    },
    calendarChange ({ start }) {
      this.start = start
      this.getData(start.year, start.month)
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showDay (event, item) {
      const open = () => {
        const self = this
        this.selectedEvent = item
        this.selectedElement = event.target
        const data = this.events.find(({ start, type }) => start === item.date && type)
        if (!data) {
          this.tab = 0
          this.time = '08:00'
        } else {
          this.tab = (this.type.indexOf(data.type) > -1) ? this.type.indexOf(data.type) : 0
          this.time = (data.time) ? data.time : '08:00'
        }
        this.startDate = item.date
        requestAnimationFrame(() => requestAnimationFrame(function () { self.selectedOpen = true }))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      event.stopPropagation()
    },
    saveEvent () {
      this.selectedOpen = false
      const payload = {
        type: this.type[this.tab],
        date: this.selectedEvent.date,
        user_id: this.user_id
      }
      switch (this.tab) {
        case 0:
          payload.time = this.time
          break
        case 2:
        case 3:
          payload.start_date = this.startDate
          payload.end_date = this.endDate
          payload.repl_id = this.repl_id
          break
      }
      this.$store.dispatch('hr/POST_EVENT_DATA', payload).then(response => {
        this.events = response.events
      })
      this.repl_id = null
    },
    deleteEvent () {
      this.selectedOpen = false
      const payload = {
        type: this.type[this.tab],
        date: this.selectedEvent.date,
        user_id: this.user_id
      }
      this.$store.dispatch('hr/DELETE_EVENT_DATA', payload).then(response => {
        this.events = response.events
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
