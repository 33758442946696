<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      title="Пользователи"
    >
      <v-row>
        <v-col cols="8">
          <v-btn v-if="$auth.check('hr')" color="primary" small outlined class="ml-auto" to="/users/create">Добавить</v-btn>
        </v-col>
        <v-col cols="12" lg="4" class="d-flex row justify-end">
          <v-switch
            v-if="$auth.check(['hr', 'director'])"
            v-model="inactive"
            label="Все"
            class="ml-12"
          />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-8"
            label="Поиск"
            hide-details
            single-line
            style="max-width: 250px;"
            clearable
          />
        </v-col>
      </v-row>

      <v-divider class="mt-3" />

      <v-data-table
        v-if="items.length>0"
        :headers="computedHeaders"
        :items="items"
        :search.sync="search"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        dense
        :options.sync="pagination"
      >
        <template v-slot:item.avatar="{ item }">
          <v-avatar size="48" color="grey">
            <v-img :src="(item.avatar === null) ? '/static/default_avatar.png' : `/api/v1/uploads/avatar/${item.avatar}`" />
          </v-avatar>
        </template>
        <template v-slot:item.department="{ item }">
          <v-chip
            v-for="(el, key) in item.department" :key="key"
            small
          >{{ el.name }}</v-chip>
        </template>
        <template v-slot:item.mobile="{ item }">
          <span v-for="(i, key) in item.mobile" :key="key">
            {{ i | VMask('+7 (###) ###-####') }}
          </span>
        </template>
        <template v-slot:item.roles="{ item }">
          <v-chip
            v-for="(el, key) in item.roles" :key="key"
            small
          >{{ el.description }}</v-chip>
        </template>
        <template v-slot:item.active="{ item }">
          <v-chip
            :color="status[item.active].color"
            :text-color="status[item.active].text_color"
            dark
            small
          >
            {{ status[item.active].text }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-btn
              icon color="accent" dark
              @click.stop="$router.push({ path: `/hr/time_sheet/${item.id}`})"
            >
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
            <v-btn
              text icon color="accent" dark class="mr-2" @click.stop="$router.push({ path: `/users/${item.id}/edit` })"
              v-if="$auth.check('hr')"
              v-tooltip.bottom="`Редактировать профиль`"
            >
              <v-icon>mdi-account-edit</v-icon>
            </v-btn>
          </div>
        </template>

      </v-data-table>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: 'List',
  data: () => ({
    sortBy: 'full_name',
    sortDesc: false,
    inactive: false,
    items: [],
    status: {
      0: { color: 'green', text: 'Активен', text_color: 'white' },
      1: { color: 'yellow', text: 'Не активен', text_color: 'black' },
      2: { color: 'red', text: 'Уволен', text_color: 'white' }
    }
  }),
  created () {
    this.getData()
  },
  computed: {
    search: {
      get: function () {
        return this.$store.state.user.userSearch
      },
      set: function (value) {
        this.$store.state.user.userSearch = value
      }
    },
    pagination: {
      get: function () {
        return this.$store.state.user.pagination
      },
      set: function (value) {
        this.$store.commit('hr/SET_PAGINATION', value)
      }
    },
    headers: function () {
      return [
        { sortable: false, value: 'avatar', width: '63px' },
        { text: 'Пользователь', value: 'full_name', width: '20%' },
        { text: 'Подразделение', value: 'department', width: '20%' },
        { text: 'Телефон', value: 'mobile', width: '150px' },
        { text: 'Должность', value: 'position' },
        { text: 'Роль', value: 'roles', visible: this.$auth.check('hr') },
        {
          text: 'Активен',
          value: 'active',
          filter: value => {
            if (this.inactive) {
              return true
            } else {
              return value === 0
            }
          }
        },
        { value: 'actions', sortable: false }
      ]
    },
    computedHeaders: function () {
      return this.headers.filter(item => item.visible || !Object.prototype.hasOwnProperty.call(item, 'visible'))
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('hr/GET_USERS').then(response => {
        this.items = response.users
      })
    },
    rowClick (event) {
      this.$router.push({ path: `/users/${event.id}/view` })
    }
  }
}
</script>

<style scoped>

</style>
