<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      title="Подразделения"
    >
      <v-row>
        <v-col cols="4">
          <v-treeview
            :items="treeList"
            dense
            activatable
            transition
            hoverable
            selection-type="independent"
            open-all
            @update:active="updateActive"
          >
            <template v-slot:label="{ item }">
              {{ item.name }}
            </template>
          </v-treeview>
        </v-col>
        <v-col cols="8">
          <v-card>
            <v-card-text>
              <v-autocomplete
                v-model="leaderId"
                :items="users"
                item-value="id"
                item-text="full_name"
                item-desc="position"
                label="Руководитель"
                avatar
                :clearable="leaderEdit"
                :readonly="!leaderEdit"
              >
                <template v-slot:append-outer>
                  <v-btn icon v-if="leaderEdit" @click="editLeaderOff" outlined>
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                  <v-btn icon v-else @click="editLeaderOn" outlined :disabled="!treeSelect">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-model="subLeaderId"
                :items="users"
                item-value="id"
                item-text="full_name"
                item-desc="position"
                label="Заместитель руководитель"
                avatar
                :clearable="subLeaderEdit"
                :readonly="!subLeaderEdit"
              >
                <template v-slot:append-outer>
                  <v-btn icon v-if="subLeaderEdit" @click="editSubLeaderOff" outlined>
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                  <v-btn icon v-else @click="editSubLeaderOn" outlined :disabled="!treeSelect">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
              <v-divider></v-divider>
              <v-data-table
                :headers="headers"
                :items="items"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                dense
              >
                <template v-slot:item.avatar="{ item }">
                  <v-avatar size="48" color="grey">
                    <v-img :src="(item.avatar === null) ? '/static/default_avatar.png' : `/api/v1/uploads/avatar/${item.avatar}`" />
                  </v-avatar>
                </template>
                <template v-slot:item.roles="{ item }">
                  <v-chip
                    v-for="(el, key) in item.roles" :key="key"
                    small
                  >{{ el.description }}</v-chip>
                </template>
              </v-data-table>
              <v-divider></v-divider>
              <v-switch
                v-model="paramsDep.ticket"
                label="Участие в заявках"
                @change="updateParams"
                :disabled="!treeSelect"
              ></v-switch>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: 'Department',
  data: () => ({
    items: [],
    headers: [
      { sortable: false, value: 'avatar', width: '63px' },
      { text: 'ФИО', value: 'short_name' },
      { text: 'Должность', value: 'position' },
      { text: 'Роль', value: 'roles' }
    ],
    treeSelect: undefined,
    sortBy: 'short_name',
    sortDesc: false,
    users: [],
    leaderId: null,
    subLeaderId: null,
    leaderEdit: false,
    subLeaderEdit: false,
    paramsDep: {
      ticket: false
    }
  }),
  computed: {
    treeList: function () {
      return this.$store.state.bpm.department
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData: function () {
      this.$store.dispatch('hr/GET_DEPARTMENT').then(response => {
        this.users = response.users
      })
    },
    refreshData: function () {
      this.$store.dispatch('hr/GET_DEPARTMENT_USERS', this.treeSelect).then(response => {
        this.items = response.users
        this.leaderId = response.leader
        this.subLeaderId = response.sub_leader
        this.paramsDep = response.params
      })
    },
    updateActive: function (value) {
      this.leaderEdit = false
      this.treeSelect = value[0]
    },
    updateParams: function () {
      const payload = {
        id: this.treeSelect,
        params: this.paramsDep
      }
      this.$store.dispatch('hr/PUT_DEPARTMENT_PARAMS', payload).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.title
        this.flashMessage.error({ title, message })
      })
    },
    editLeaderOn: function () {
      this.leaderEdit = true
    },
    editLeaderOff: function () {
      this.leaderEdit = false
      const payload = {
        leader_id: (this.leaderId) ? this.leaderId : -1
      }
      this.$store.dispatch('hr/PUT_LEADER', { id: this.treeSelect, payload: payload }).then(() => {
        this.flashMessage.success({ title: 'Сохранение.', message: 'Руководитель успешно сохранен.' })
      })
    },
    editSubLeaderOn: function () {
      this.subLeaderEdit = true
    },
    editSubLeaderOff: function () {
      this.subLeaderEdit = false
      const payload = {
        sub_leader_id: (this.subLeaderId) ? this.subLeaderId : -1
      }
      this.$store.dispatch('hr/PUT_LEADER', { id: this.treeSelect, payload: payload }).then(() => {
        this.flashMessage.success({ title: 'Сохранение.', message: 'Заместитель руководителя успешно сохранен.' })
      })
    }
  },
  watch: {
    treeSelect (val) {
      if (val) {
        this.refreshData()
      } else {
        this.items = []
        this.leaderId = undefined
        this.subLeaderId = undefined
        this.paramsDep.ticket = false
      }
    }
  }
}
</script>

<style scoped>

</style>
