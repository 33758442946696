<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      title="Производственный календарь"
    >
      <v-toolbar flat>
        <v-row>
          <v-col cols="12" lg="10" class="d-flex justify-start">
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Сегодня</v-btn>
            <v-btn outlined fab text small color="grey darken-2" icon @click="prev"><v-icon small>mdi-chevron-left</v-icon></v-btn>
            <v-btn outlined fab text small color="grey darken-2" icon @click="next"><v-icon small>mdi-chevron-right</v-icon></v-btn>
            <v-toolbar-title class="ml-4 text-uppercase">{{ calendarTitle }}</v-toolbar-title>
          </v-col>
          <v-col cols="12" lg="2" class="d-flex justify-end" v-if="$auth.check('hr')">
            <v-integer v-model="year" label="Год" hide-details></v-integer>
            <v-btn outlined class="ml-4" color="grey darken-2" @click="updateCalendar">Получить</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-row>
        <v-col cols="12">
          <v-calendar
            ref="calendar"
            color="primary"
            v-model="focus"
            :events="events"
            type="month"
            locale="ru-ru"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            @change="calendarChange"
            show-week
            :short-weekdays="false"
          ></v-calendar>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column">
          <div class="d-flex justify-start align-center mb-3">
            <v-chip x-small color="red" style="width: 100px"></v-chip><span>&nbsp;- Выходной</span>
          </div>
          <div class="d-flex justify-start align-center">
            <v-chip x-small color="orange" style="width: 100px"></v-chip><span>&nbsp;- Короткий день</span>
          </div>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import VInteger from '../../components/VInteger'
export default {
  name: 'ProductionCalendar',
  components: { VInteger },
  data: () => ({
    events: [],
    focus: '',
    year: new Date().getFullYear(),
    start: null
  }),
  computed: {
    calendarTitle () {
      if (this.focus === '') {
        return this.$moment().format('MMMM YYYY')
      }
      return this.$moment(this.focus, 'YYYY-MM-DD').format('MMMM YYYY')
    }
  },
  methods: {
    getData (year, month) {
      this.$store.dispatch('hr/GET_CALENDAR', { year: year, month: month }).then(response => {
        this.events = response.events
      })
    },
    updateCalendar () {
      this.$store.dispatch('hr/GET_UPDATE_CALENDAR', { year: this.year }).then(() => {
        this.flashMessage.success({ title: 'Обновление', message: 'Данные обновлены' })
        this.getData(this.start.year, this.start.month)
      }).catch(error => {
        const message = error.response.data.message || ''
        this.flashMessage.error({ title: 'Обновление', message: message })
      })
    },
    calendarChange ({ start }) {
      this.start = start
      this.getData(start.year, start.month)
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    }
  }
}
</script>

<style scoped>

</style>
