var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-briefcase","inline":"","title":"Пользователи"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[(_vm.$auth.check('hr'))?_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","small":"","outlined":"","to":"/users/create"}},[_vm._v("Добавить")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex row justify-end",attrs:{"cols":"12","lg":"4"}},[(_vm.$auth.check(['hr', 'director']))?_c('v-switch',{staticClass:"ml-12",attrs:{"label":"Все"},model:{value:(_vm.inactive),callback:function ($$v) {_vm.inactive=$$v},expression:"inactive"}}):_vm._e(),_c('v-text-field',{staticClass:"ml-8",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Поиск","hide-details":"","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider',{staticClass:"mt-3"}),(_vm.items.length>0)?_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"dense":"","options":_vm.pagination},on:{"update:search":function($event){_vm.search=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"48","color":"grey"}},[_c('v-img',{attrs:{"src":(item.avatar === null) ? '/static/default_avatar.png' : ("/api/v1/uploads/avatar/" + (item.avatar))}})],1)]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return _vm._l((item.department),function(el,key){return _c('v-chip',{key:key,attrs:{"small":""}},[_vm._v(_vm._s(el.name))])})}},{key:"item.mobile",fn:function(ref){
var item = ref.item;
return _vm._l((item.mobile),function(i,key){return _c('span',{key:key},[_vm._v(" "+_vm._s(_vm._f("VMask")(i,'+7 (###) ###-####'))+" ")])})}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return _vm._l((item.roles),function(el,key){return _c('v-chip',{key:key,attrs:{"small":""}},[_vm._v(_vm._s(el.description))])})}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.status[item.active].color,"text-color":_vm.status[item.active].text_color,"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.status[item.active].text)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":"","color":"accent","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ path: ("/hr/time_sheet/" + (item.id))})}}},[_c('v-icon',[_vm._v("mdi-calendar")])],1),(_vm.$auth.check('hr'))?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:("Редактировать профиль"),expression:"`Редактировать профиль`",modifiers:{"bottom":true}}],staticClass:"mr-2",attrs:{"text":"","icon":"","color":"accent","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ path: ("/users/" + (item.id) + "/edit") })}}},[_c('v-icon',[_vm._v("mdi-account-edit")])],1):_vm._e()],1)]}}],null,false,1517990360)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }